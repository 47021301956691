import { LdsService, LpServicesService, MdsService } from '@service';
import { VictaDupeCheckClient } from '@ropto/victa-dupe-check-client';
import { VictaProductEnum } from '@enum';
import { environmentStage, isBrowser } from '@src/lib';

if (isBrowser) {
  window.location.href = `https://mymoneyback.co.uk/uc-pcp${location.search}`;
}

const integrationApiKey = {
  prod: 'Fc1uH0uFEu49ABnvPZsUn61vNvcfLQ5a9022tA5I',
  dev: 'sX1916xRfQe2V0DyumAI9PRjpkfzwSm3YSvC8Q29'
}

export const ldsService: LdsService = isBrowser ? new LdsService({
  baseUrl: process.env.LDS_URL || 'https://r1rqj5sxlc.execute-api.eu-west-1.amazonaws.com',
  // opts: { productId: '371', clientId: '94' },
  opts: { clientId: '94' },
  initHeaders: { 'x-api-key': integrationApiKey[environmentStage()]  }
}) : {} as any;

export const mdsService: MdsService = new MdsService({
  baseUrl: process.env.MDS_URL || 'https://v7njlb8x7i.execute-api.eu-west-2.amazonaws.com',
  // opts: { productId: '399', clientId: '1' },
  opts: { clientId: '1' },
  storageKey: 'mdsLeadId',
  resumeKeyKey: 'tcglPcpExternalLp011Resume',
});

export const lpServicesService: LpServicesService = new LpServicesService({
  baseUrl: process.env.LP_SERVICES_URL || 'https://1spvcfs52c.execute-api.eu-west-1.amazonaws.com',
  opts: { product: VictaProductEnum.UNDISCLOSED_COMMISSION },
});

export const vdcService = new VictaDupeCheckClient(process.env.VDC_URL || 'https://9kks2axbzh.execute-api.eu-west-1.amazonaws.com/dev/');
